import React, {useEffect, useState, useRef} from "react";

import moment from "moment";
import {useCState} from "./CState";
import {useInterval} from "./Hooks";

export const SEC = 1000;
export const MINUTE = 60 * SEC;
export const HOUR = 60 * MINUTE;
export const gameStartedKey = "gameStarted";
export const gameStoppedKey = "gameStopped";

const Chrono = () => {

    const [now, setNow] = useState(Date.now());
    const [gameStarted, _1] = useCState(gameStartedKey);
    const [gameStopped, _2] = useCState(gameStoppedKey);

    useInterval(() => {
        if (gameStopped === undefined)
            setNow(Date.now());
        else
            setNow(gameStopped);
    }, 500);

    if (!gameStarted)
        return null;

    // console.log("now",now)
    let duration = now - gameStarted;
    // console.log("duration",duration)
    if (gameStopped) duration = gameStopped - gameStarted;
    if (duration < 0) duration = 0;


    let format = "mm:ss";
    if (duration > HOUR) format = "HH:mm";

    if (duration % 1000 < 500
        && gameStopped === undefined
    )
        format = format.replace(":", " ");

    let text = moment.utc(duration).format(format);



    const width = "5rem";

    return (
        <div
            style={{
                // position: "absolute",
                // flexDirection: "row",
                // justifyContent: "flex-end",
                // alignItems: "center"
            }}
        >
            <img
                src={require("./gameChrono.png").default}
                style={{
                    // flex: 1,
                    right: "2vw",
                    top: "2vw",
                    alignItems: "center",
                    justifyContent: "center",
                    position: 'absolute',
                    width,
                    height: width
                }}
            >

            </img>
            <div
                style={{
                    width: width,
                    height: width,
                    right: "2vw",
                    top: "2vw",
                    display:"flex",
                    alignItems:"center",
                    fontSize: '1.3rem',
                    fontFamily: 'monospace',
                    fontWeight: 'bold',
                    position: 'absolute',
                    textAlign: 'center',
                    justifyContent: 'center',
                    color: 'yellow'
                }}
            >
                {text}
            </div>
        </div>
    );
};

export default Chrono;
