import {useEffect, useRef, useState} from "react";
import {useCState} from "../../CState";
import {completeResponse} from "../../config";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";

const numbers = "0123456789".split("");
const letters = " LCIVXLCDM".split("");
const answer = [5,8,3,0,3,3];

function prev(symbols, indexes, i) {
    // if (i===0) {
    //     console.log("prev", symbols, indexes, i)
    //     console.log("indexes[i]", indexes[i])
    //     console.log("symbols.length", symbols.length)
    //     console.log("symbols[symbols.length - 1]", symbols[symbols.length - 1])
    //     console.log("symbols[indexes[i] - 1]", symbols[indexes[i] - 1])
    // }

    if (indexes[i] === 0) {
        return symbols[symbols.length - 1];
    }

    return symbols[indexes[i] - 1];
}

function next(symbols, indexes, i) {
    if (indexes[i] === symbols.length - 1)
        return symbols[0];
    return symbols[indexes[i] + 1];
}

const numberStyle = {
    position: "absolute",
    fontSize: '41.6px',
    cursor: 'pointer',
    // backgroundColor: 'rgba(255,208,0,0.44)',
    color: 'white',
    width: '42px',
    height: '42px',
    textAlign: 'center',

}

const left = 216
const leftStep = 49

export const Barrillet = () => {

    const [indexes, setIndexes] = useState(completeResponse ? [5,8,3,0,2,3] : [0,0,0,0,0,0])
    const setPage2d = useSetRecoilState(page2dState);

    // const [unlocked, setUnlocked] = useCState(lockerUnlockedKey);
    const rows = [];

    useEffect(() => {
        // console.log("indexes", indexes)
        if (indexes.join("") === answer.join("")) {
            setTimeout(() => {
                setPage2d("End")
            },1000)
        }
    },[indexes])

    for (let i = 0; i < 6; i++) {
        const turn = () => {
            const newIndexes = [...indexes];
            newIndexes[i] = (newIndexes[i] + 1) % numbers.length;
            setIndexes(newIndexes);
        }

        let symbols = [...numbers];
        if (i > 2)
            symbols = [...letters];
        // TOP
        rows.push(<div
            key={i}
            style={{
                ...numberStyle,
                top: "428px",
                left: (left + leftStep * i)+"px",
                opacity: 0.8,
                transform: "scaleY(0.8)"
            }}
            onClick={turn}
        >
            {next(symbols,indexes,i)}
        </div>)
        // MIDDLE
        rows.push(<div
            style={{
                ...numberStyle,
                top: "500px",
                left: (left + leftStep * i)+"px",
            }}
            onClick={turn}
        >
            {symbols[indexes[i]]}
        </div>)
        // BOTTOM
        rows.push(<div
            style={{
                ...numberStyle,
                top: "573px",
                left: (left + leftStep * i)+"px",
                opacity: 0.8,
                transform: "scaleY(0.8)"
            }}
            onClick={turn}
        >
            {prev(symbols,indexes,i)}
        </div>)
    }


    return (<div
        style={{
            display: 'flex',
            position: "relative",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                position: "absolute",
                width: '700px',
                height: '700px'
            }}
        >
            <img
                src={require("./barrillet.png").default}
                style={{
                    position: "absolute",
                    width: '700px',
                    height: '700px'
                }}
            />
            {rows}
        </div>
    </div>)
}