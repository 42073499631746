import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene} from "../../Zone";
import {useSetRecoilState} from "recoil";
import {sceneKey, screenState} from "../../Scene";
import {page2dState} from "../../Page2d";
import {useCState} from "../../CState";
import {oncleUnlockedKey} from "../porteOncle/PorteOncle";

export function Paint2() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./paint2.jpg').default);
    const [unlocked, setUnlocked] = useCState(oncleUnlockedKey);
    const [_s,setScreen] = useCState(sceneKey);
    const setPage2d = useSetRecoilState(page2dState);


    return <group>

        <mesh rotation={[0, 0, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        {/*<ToScene name={"Paint2"} y={-0} z={10} />*/}
        <ToScene name={"Paint1"} y={-0.9} z={180} />
        <To2d name={"Triphase"} y={-0.2} z={227} />
        <To2d name={"Puzzle"} y={-0.2} z={17} />

        <ClickZone y={-0.2} z={350}
                   callback ={(event) => {
                       if (unlocked)
                           setScreen("Oncle");
                       else
                           setPage2d("PorteOncle");
                   }}></ClickZone>
    </group>
}