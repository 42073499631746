import {ReactMagnifier} from "@sandeepv68/react-magnifier";
export const Portraits = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <ReactMagnifier
            imageUrl={require('./portraits.png').default}
            zoomSize={3}
            imageAltText={"Some text"}
            imageHeight={400}
            imageWidth={1200}
            magnifierHeight={50}
            magnifierWidth={50}
            magnifierRadius={50}
            magnifierBorderColor={"white"}
            magnifierBorderStyle={"solid"}
            magnifierBorderWidth={2}
            magnifierShadow={true}
            cursor={"none"}
            customImgStyles={'myClass'}
            customContainerStyles={'aClass'}
        />

    </div>)
}