import {atom, selector, useRecoilValue} from "recoil";
import {Balcon} from "./scene1/360/balcon/Balcon";
import {Cuisine} from "./scene1/360/cuisine/Cuisine";
import {Hall} from "./scene1/360/hall/Hall";
import {Salon} from "./scene1/360/salon/Salon";
import {Sdb} from "./scene1/360/sdb/Sdb";
import {Chambre} from "./scene1/360/chambre/Chambre";
import {defaultScene} from "./config";
import {Front} from "./scene2/360/Front/Front";
import {Paint1} from "./scene3/Paint1/Paint1";
import {Paint2} from "./scene3/Paint2/Paint2";
import {Oncle} from "./scene4/Oncle/Oncle";
import {Associate} from "./scene5/Associate/Associate";
import {Remise} from "./scene6/remise/Remise";
import {useCState} from "./CState";

export const navState = atom({
    key: 'screenNav',
    default: {
        name: defaultScene,
    },
});

export const screenState = selector({
    key: 'screen',
    get: ({get}) => {
        return get(navState).name;
    },
    set: ({set,get }, newValue) =>
        set(navState, {...get(navState), name: newValue})
});

export const screens = {
    Chambre,
    Balcon,
    Cuisine,
    Hall,
    Salon,
    Sdb,
    Front,
    Paint1,
    Paint2,
    Oncle,
    Associate,
    Remise,
}

export const sceneKey = "sceneKey";

function Scene() {
    // const screenName = useRecoilValue(screenState);
    let [screenName,_] = useCState(sceneKey);
    if (!screenName)
        screenName = defaultScene;
    // console.log("screen.name :", screenName)

    let ScreenComp = screens[screenName];
    // console.log("screenComp :", ScreenComp)
    if (ScreenComp) {
        return <ScreenComp/>
    } else
        // console.log("screens :", screens)

    return <Chambre/>
}

export default Scene;