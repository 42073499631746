import {useCState} from "../../../CState";
import moment from "moment";
import {useAudio, useInterval} from "../../../Hooks";
import {useEffect, useState} from "react";
import {showSquare} from "../../../config";
import {factsUnlockedKey} from "../../../scene4/Calc/Calc";
import {answeredKey} from "./Smartphone";
import {lockerUnlockedKey} from "../../../scene5/Locker/Locker";
import {lockerAvailableKey} from "../../../scene5/Associate/Associate";
import {addDocument} from "../../../documents";


/*
Salut c'est Annabelle, j'espère que ton enquête se passe bien. C'est trop bizarre, je viens de recevoir un email de mon oncle.
Il m'indique que s'il lui arrivait malheur, je pouvais ouvrir le coffre dans le bureau de son associé en saisissant
le montant auquel j'ai droit en cas de dissolution.
* */

/*
Salut c'est encore Annabelle. Je me demandais qui va s'occuper de liquider la société de mon oncle ?
J'espère que ce ne sont pas les associés ! Fait attention à toi, les securitas vont pas tarder à arriver.
Sors par le garage. C'est plus prudent.
 */

export const Call = ({setStep}) => {

    const [now, setNow] = useState(null);
    const [gameStarted, _] = useCState("gameStarted");
    const [playingEmail, toggleEmail] = useAudio("/doc/annabelleMessage1.mp3");
    const [playingOncle, toggleOncle] = useAudio("/doc/annabelleMessage2.mp3");
    const [playingSecuritas, toggleSecuritas] = useAudio("/doc/annabelleMessage3.mp3");;
    const [calcUnlocked, _1] = useCState(factsUnlockedKey);
    const [lockerSuccess, _2] = useCState(lockerUnlockedKey);
    const [_3, setLockerAvailable] = useCState(lockerAvailableKey);

    useInterval(() => {
        setNow(Date.now());
    }, 1000);

    let date;
    if (now) {
        const startedDuration = Date.now() - gameStarted;
        date = new Date();
        date.setHours(18, 0, 0);
        // date.setTime(date.getTime()+startedDuration)
        date.setTime(date.getTime() + startedDuration)
        // console.log("date " + date.getTime())
    }

    let step = "start"
    if (calcUnlocked)
        step = "calcUnlocked"
    if (lockerSuccess)
        step = "lockerSuccess"

    // console.log("step " + step)
    // console.log("calcUnlocked " + calcUnlocked)
    // console.log("lockerSuccess " + lockerSuccess)



    const images = {
        start: require("./call.png").default,
        calcUnlocked: require("./call2.png").default,
        lockerSuccess: require("./call3.png").default,
    }
    const player = {
        start: toggleEmail,
        calcUnlocked: toggleOncle,
        lockerSuccess: toggleSecuritas,
    }

    const playing = {
        start: playingEmail,
        calcUnlocked: playingOncle,
        lockerSuccess: playingSecuritas,
    }

    return (<div
        style={{
            width: '324px',
            height: '650px',
            position: 'relative',
            backgroundImage: `url(${images[step]})`
        }}>
        {date && <div style={{
            position: 'absolute',
            top: '25px',
            left: '30px',
            color: '#ffffffe3',
            fontSize: '10px',
            fontFamily: 'arial',
        }}>
            {moment(date).format('HH:mm')}

        </div>}
        <img
            onClick={() => {
                player[step]();
                if (step === "calcUnlocked")
                    setLockerAvailable(true);
                if (step === "calcUnlocked")
                    addDocument("msgAnna2")
                if (step === "lockerSuccess")
                    addDocument("msgAnna3")
            }}
            src={playing[step] ? require("./pause.png").default : require("./play.png").default}
            style={{
                position: 'absolute',
                top: '108px',
                left: '271px',
                width: '24px',
                height: '25px',
                fontFamily: 'arial'
            }}/>

        <div
            onClick={() => {
                // console.log("setStep")
                setStep("desktop");
            }}
            style={{
                position: 'absolute',
                bottom: '25px',
                left: '17px',
                width: '290px',
                height: '32px',
                // backgroundColor: 'yellow'
                backgroundColor: showSquare ? "yellow": "transparent",
            }}>
        </div>
    </div>)
}