import {useCState} from "../../../CState";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../../Page2d";
import {useEffect, useRef, useState} from "react";
import {sceneKey, screenState} from "../../../Scene";
import {galerieUnlockedKey} from "../../360/Front/Front";
import {showSquare} from "../../../config";


export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};

const points = [];
const points2Arr = [];
for (let iX = 0; iX < 6; iX++) {
    for (let iY = 0; iY < 3; iY++) {
        let point = {
            x: iX,
            y: iY,
            left: (121 + iX * 68),
            top: (68 + iY * 60),
            shiftX: iX * 68,
            shiftY: iY * 60,
        };
        points.push(point);
        if (!points2Arr[iX])
            points2Arr[iX] = []
        points2Arr[iX][iY] = point
    }
}

const answer = [[[1, 0], [0, 0]], [[0, 0], [0, 1]], [[0, 1], [1, 1]], [[1, 1], [1, 2]], [[1, 2], [0, 2]], [[2, 2], [2, 1]], [[2, 1], [2, 0]], [[2, 0], [3, 2]], [[3, 2], [3, 1]], [[3, 1], [3, 0]], [[5, 0], [4, 0]], [[4, 0], [4, 1]], [[4, 1], [4, 2]], [[4, 2], [5, 2]]]
const answer2 = [[[1,0],[0,0]],[[0,0],[0,1]],[[0,1],[1,1]],[[1,1],[1,2]],[[1,2],[0,2]],[[2,2],[2,0]],[[2,0],[3,2]],[[3,2],[3,0]],[[5,0],[4,0]],[[4,0],[4,2]],[[4,2],[5,2]],]

function samePoint(pointA, pointB) {
    return pointA[0] === pointB[0] && pointA[1] === pointB[1]
}

function sameTrace(pointStartA, pointEndA, point1B, point2B) {
    if (samePoint(pointStartA, point1B) && samePoint(pointEndA, point2B))
        return true;
    if (samePoint(pointStartA, point2B) && samePoint(pointEndA, point1B))
        return true;
    return false;
}


function checkIsAnswer(traces, answer) {

    for (const answerTrace of answer) {
        const [startAX, startAY] = answerTrace[0];
        const [endAX, endAY] = answerTrace[1];
        const hasValue = traces.find(trace => {
            const [startPoint, endPoint] = trace;
            return sameTrace([startPoint.x, startPoint.y],
                [endPoint.x, endPoint.y],
                [startAX, startAY],
                [endAX, endAY]
            )
        });
        if (!hasValue) {
            // console.log("!checkIsAnwer1 ", answerTrace)
            return false;
        }
    }
    for (const [startPoint, endPoint] of traces) {
        if (sameUserPoint(startPoint, endPoint))
            continue;
        const hasValue = answer.find(answerTrace => {
            const [startXY, endXY] = answerTrace;
            return sameTrace([startPoint.x, startPoint.y],
                [endPoint.x, endPoint.y],
                startXY,
                endXY
            )
        });
        if (!hasValue) {
            // console.log("!checkIsAnwer2 ", [startPoint.x, startPoint.y], [endPoint.x, endPoint.y])
            return false;
        }
    }
    // console.log("IsAnswer!!!!!!!!")

    return true;
}

function sameBlock(startPoint, point) {
    if (!startPoint)
        return false;
    return Math.floor(startPoint.x / 2) === Math.floor(point.x / 2);
}

function sameUserPoint(startPoint, point) {
    if (!startPoint)
        return false;
    return startPoint.x === point.x && startPoint.y === point.y
}

export const PorteGalerie = () => {

    const [traces, setTraces] = useState([])
    const [startPoint, setStartPoint] = useState(null)
    const [unlocked, setUnlocked] = useCState(galerieUnlockedKey);
    const setPage2d = useSetRecoilState(page2dState);
    const [_s,setScreen] = useCState(sceneKey);
    const canvas = useRef();
    let arr = "["
    traces.forEach(trace => {
        arr += "[["+trace[0].x+","+trace[0].y+"],";
        arr += "["+trace[1].x+","+trace[1].y+"]],";
    })
    // console.log(arr+"]")
    // console.log("traces ", traces)
    // console.log("startPoint ", startPoint)
    useEffect(() => {
        const canvasEle = canvas.current;
        const ctx = canvasEle.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        traces.forEach((line) => {
            const pointA = line[0];
            const pointB = line[1];
            ctx.beginPath();
            ctx.moveTo(pointA.shiftX, pointA.shiftY);
            ctx.lineTo(pointB.shiftX, pointB.shiftY);
            ctx.lineWidth = 5;
            ctx.stroke();
        })

    });
    useEffect(()=>{
        if (checkIsAnswer(traces,answer) || checkIsAnswer(traces,answer2)) {
            setUnlocked(true);
            setTimeout(() => {
                setScreen("Paint1");
                setPage2d(null);
            }, 5000);
        }
    },[traces])

    const pointDivs = []
    points.forEach((point) => {
        pointDivs.push(<div
            style={{
                cursor: "pointer",
                position: "absolute",
                left: point.left + 'px',
                top: point.top + 'px',
                width: '20px',
                height: '20px',
                borderRadius: '50px',
                background: sameUserPoint(startPoint, point) ? "black" : "white"
            }}
            onClick={(e) => {
                e.stopPropagation();
                if (startPoint && !sameBlock(startPoint, point)) {
                    setStartPoint(point);
                    return;
                }
                if (startPoint) {
                    setTraces([...traces,
                        [startPoint, point]
                    ])
                }
                setStartPoint(point);
            }}
        ></div>)
    })


    return (<div
        style={{
            display: 'flex',
            position: "relative",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
        onClick={() => {
            setStartPoint(null)
        }}
    >
        <div
            style={{
                position: "absolute",
                width: '600px',
                height: '400px'
            }}
        >

            <img
                src={require("./digicode.png").default}
                style={{
                    position: "absolute",
                    width: '600px',
                    height: '400px'
                }}
            />
            {unlocked && <img
                src={require("./digicodeGreen.png").default}
                style={{
                    position: "absolute",
                    width: '600px',
                    height: '400px'
                }}
            />}
            <div
                style={{
                    position: "absolute",
                    left: '260px',
                    top: '260px',
                    width: '80px',
                    height: '70px',
                    background: showSquare ? "yellow" : "transparent"
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    setStartPoint(null);
                    setTraces([]);
                    // console.log("reset")
                }}
            ></div>
            <canvas
                width={420}
                height={180}
                style={{
                    position: "absolute",
                    left: '131px',
                    top: '78px',
                    width: '600px !important',
                    height: '180px !important'
                }}
                ref={canvas}></canvas>
            {pointDivs}
        </div>
    </div>)
}