import {useSetRecoilState} from "recoil";
import {sceneKey, screenState} from "./Scene";
import * as THREE from "three";
import {showSquare} from "./config";
import {MathUtils} from "three";
import {page2dState} from "./Page2d";
import {useCState} from "./CState";

export const ToScene = ({name, z, y}) => {
    // const [_s,setScreen] = useCState(sceneKey);
    const [_,setScreen] = useCState(sceneKey);
    return <Zone {...{z, y}} onClick={(event) => {
        // console.log("event :", event);
        if (event.delta < 5)
            setScreen(name);
    }}></Zone>
}
export const To2d = ({name, z, y}) => {
    const setPage2d = useSetRecoilState(page2dState);
    return <Zone {...{z, y}} onClick={(event) => {
        if (event.delta < 5)
            setPage2d(name);
    }}></Zone>
}
export const BigTo2d = ({name, z, y}) => {
    const setPage2d = useSetRecoilState(page2dState);
    return <BigZone {...{z, y}} onClick={(event) => {
        if (event.delta < 5)
            setPage2d(name);
    }}></BigZone>
}

export const ClickZone = ({callback, z, y}) => {
    return <Zone {...{z, y}} onClick={(event) => {
        if (event.delta < 5)
            callback();
    }}></Zone>
}

export const ClickDocs = ({docs, z, y}) => {
    return <ClickZone y={y} z={z} callback={() => {
        docs.forEach(doc => {
            window.open("/doc/" + doc);
        });
    }
    }/>
}


export const Zone = ({onClick, z, y}) => {
    if (!z)
        z = 0;
    if (!y)
        y = 0;
    return (
        // <group position={[0, 0, 0]} rotation={[0, y, z]}>
        <group position={[0, y, 0]} rotation={[0, MathUtils.degToRad(z), 0]}>
            <mesh position={[0, 0, -2]} onClick={onClick}>
                <planeBufferGeometry attach="geometry" args={[0.5, 1]}/>
                <meshBasicMaterial attach="material" color="yellow" side={THREE.DoubleSide} opacity={showSquare}
                                   transparent/>
            </mesh>
        </group>
    );
}

export const BigZone = ({onClick, z, y}) => {
    if (!z)
        z = 0;
    if (!y)
        y = 0;
    return (
        // <group position={[0, 0, 0]} rotation={[0, y, z]}>
        <group position={[0, y, 0]} rotation={[0, MathUtils.degToRad(z), 0]}>
            <mesh position={[0, 0, -2]} onClick={onClick}>
                <planeBufferGeometry attach="geometry" args={[1, 3]}/>
                <meshBasicMaterial attach="material" color="yellow" side={THREE.DoubleSide} opacity={showSquare}
                                   transparent/>
            </mesh>
        </group>
    );
}