import {useCallback, useLayoutEffect, useState} from 'react';
import Cookies from "js-cookie";

export const isLand = ()=>{
    return window.innerWidth >  window.innerHeight
}

// export const useBbox = () => {
//     const ref = useRef();
//     const [bbox, setBbox] = useState({});
//
//     const set = () =>
//         setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});
//
//     useLayoutEffect(() => {
//         set();
//         window.addEventListener('resize', set);
//         return () => window.removeEventListener('resize', set);
//     }, []);
//
//     return [bbox, ref];
// };

export const event = (name)=>{
    // fetch("https://marionbruno.fr/api/event.php?1="+name)
    //     .then(res => console.log("event :",res)).catch(error => console.log("event :",error))
}

export const arrayEquals = (array1, array2) => {
    return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}
export const arrayComp = (array1, array2) => {
    const array2Sorted = array2.slice().sort();
    return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
        return value === array2Sorted[index];
    });
}

export const log = (msg)=>{
    // console.log(msg)
}

export const shuffleObj = (unshuffled)=> {
    return  Object.keys(unshuffled)
        .map((key) => ({key, value: unshuffled[key]}))
        .sort((a, b) => b.key.localeCompare(a.key))
        .reduce((acc, e) => {
            acc[e.key] = e.value;
            return acc;
        }, {});
}

export const useRect = (ref) => {
    const [rect, setRect] = useState(getRect(ref ? ref.current : null))

    const handleResize = useCallback(() => {
        if (!ref.current) {
            return
        }

        // Update client rect
        setRect(getRect(ref.current))
    }, [ref])

    useLayoutEffect(() => {
        const element = ref.current
        if (!element) {
            return
        }

        handleResize()

        if (typeof ResizeObserver === 'function') {
            let resizeObserver = new ResizeObserver(() => handleResize())
            resizeObserver.observe(element)

            return () => {
                if (!resizeObserver) {
                    return
                }

                resizeObserver.disconnect()
                resizeObserver = null
            }
        } else {
            // Browser support, remove freely
            window.addEventListener('resize', handleResize)

            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [ref.current])

    return rect
}

function getRect(element) {
    if (!element) {
        return {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
        }
    }

    return element.getBoundingClientRect()
}

export function msToHMS( timeDiff ) {
    if (!timeDiff)
        return ""
    return new Date(timeDiff).toISOString().slice(11,19)

}

export const persistC = (key, value) => {
    Cookies.set(key, JSON.stringify(value));
}
export const loadC = (key, def = null) => {
    const value = Cookies.get(key);
    if (!value) return def;
    try {
        return JSON.parse(value);
    } catch (e) {
        return def;
    }
}