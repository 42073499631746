import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene, Zone} from "../../../Zone";
import {useEffect, useState} from "react";
import {addItem, hasItem, useItem} from "../../../BackPack";
import {useSetRecoilState} from "recoil";
import {sceneKey, screenState} from "../../../Scene";
import {page2dState} from "../../../Page2d";
import {useCState} from "../../../CState";
import {gameStartedKey} from "../../../Chrono";
import {smartphoneScreenKey} from "../../2d/smartphone/Smartphone";

export function Hall() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./hall.jpg').default);
    const setPage2d = useSetRecoilState(page2dState);
    const [_, setStep] = useCState(smartphoneScreenKey, "desktop");
    const [isSmartPhone, setIsSmartPhone] = useItem("Smartphone");

    return <group rotation={[0, 0.8 * Math.PI, 0]}>

        <mesh rotation={[0, Math.PI, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        <ToScene name={"Sdb"} y={-0.0} z={324}/>
        <ToScene name={"Chambre"} y={-2} z={262}/>
        <ToScene name={"Cuisine"} y={-1.5} z={194}/>
        <Zone y={-0.2} z={77}
              onClick={(event) => {
                  if (event.delta < 5 && isSmartPhone) {
                      setStep("map");
                      setPage2d("Smartphone");
                  }
              }}></Zone>
    </group>
}

