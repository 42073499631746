import React, {useEffect, useState, useRef} from "react";

export const setToast = (text) => {
    // console.log("setToast",text)
    ref.setText({text, start: Date.now()});
}

const ref = {
    text: {text: null, start: null},
    setText: () => {
        throw Error("BackPack fakeUpdate")
    }
};

const Toast = () => {

    [ref.text, ref.setText] = useState({text: null, start: null});
    const [visible, setVisible] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const visibleRef = useRef(null);
    const collapsedRef = useRef(null);

    // if (visibleRef.current)
    //     clearTimeout(visibleRef.current);
    // visibleRef.current = setTimeout(() => {
    //     setVisible(false);
    // }, 10000)

    useEffect(() => {
        // console.log("useEffect ref.text")
        if (!visible && ref.text.text) {
            setVisible(true);
            setCollapsed(false);
            if (visibleRef.current)
                clearTimeout(visibleRef.current);
            if (collapsedRef.current)
                clearTimeout(collapsedRef.current);
            visibleRef.current = setTimeout(() => {
                setVisible(false);
            }, 10000)
            collapsedRef.current = setTimeout(() => {
                setCollapsed(true);
            }, 11000)
        }
    }, [ref.text]);

    if (collapsed || !ref.text.text) {
        return null;
    }

    return (
        <div
            className={"row " + (visible ? 'fadeIn' : 'fadeOut')}
            style={{
                position: "absolute",
                bottom: "0",
                padding: "1rem",
                width: "100%",
                // marginLeft: "20%",
                justifyContent: "center",
                zIndex: "100000000000000",
                cursor: "pointer",

            }}
            onClick={() => {
                setVisible(false);
                setCollapsed(true);
            }
            }
        >
            <div style={{
                padding: "1rem",
                background: 'rgba(0,0,0,0.55)',
                borderRadius: '0.5rem',
                color: "white",
                fontSize: "2rem",
                textAlign: "center",
            }}>

                {ref.text.text}
            </div>
        </div>
    );
};

export default Toast;
