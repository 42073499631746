import React, {useEffect, useState, useRef} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {gyroEnabledState} from "./App";
import Cookies from "js-cookie";
import {arrayComp} from "./util";
import {page2dState} from "./Page2d";
import {initItems, items} from "./config";
import {useCState} from "./CState";

export const backPackItemsKey = "backPackItemsKey";

export const useItem = (itemName) => {
    let [itemList, setItems] = useCState(backPackItemsKey);
    if (!itemList)
        itemList = [];

    return [itemList.includes(itemName), (value) => {
        if (value === false) {
            setItems([...itemList.filter(item => item !== itemName)])
        } else {
            if (!itemList.includes(itemName)) {
                itemList.push(itemName);
            }
            setItems([...itemList])
        }
    }];
}

const imgs = {
    Folder: require("./folder.png").default,
    Smartphone: require("./smartphone.png").default,
    Calc: require("./calc.png").default,
    garage: require("./garage.png").default,
};

const BackPack = () => {

    const [items, setItems] = useCState(backPackItemsKey);
    const [open, setOpen] = useState(true);
    const setPage2d = useSetRecoilState(page2dState);

    if (!items || items.length === 0)
        return null;

    if (initItems){
        initItems.forEach(item => {
            if (!items.includes(item))
                items.push(item);
        })
    }

    return (
        <div
            className={"row"}
            style={{
                position: "absolute",
                width: "calc(100% - 2rem)",
                bottom: "0",
                padding: "1rem",
                justifyContent: "flex-end",
                zIndex: "100",
            }}
        >
            {open && items.map(item =>
                <img className={"hover"}
                     key={item}
                     src={imgs[item]}
                     onClick={() => {
                         if (item === "Folder")
                             window.open("/?classeur=1","classeur");
                         else
                             setPage2d(item);
                     }}
                     style={{
                         height: '5rem',
                         background: 'black',
                         padding: '0.5rem',
                         borderRadius: '0.5rem',
                         border: 'yellow solid 0.1rem',
                         marginRight: "0.5rem",
                         objectFit: 'contain',
                     }}
                />)
            }
            <img className={"hover"}
                 onClick={() => setOpen(!open)}
                 style={{
                     height: '5rem',
                     background: 'black',
                     padding: '0.5rem',
                     width: '5rem',
                     objectFit: 'contain',
                     borderRadius: '0.5rem',
                     border: open ? 'white solid 0.1rem' : 'yellow solid 0.1rem'
                 }} src={require("./backpack.png").default}
            />
        </div>
    );
};

export default BackPack;
