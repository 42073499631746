import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene} from "../../../Zone";
import {useEffect, useState} from "react";
import {addItem, hasItem} from "../../../BackPack";

export function Salon() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./salon.jpg').default);

    return <group  rotation={[0, 0 * Math.PI, 0]}>

        <mesh rotation={[0, Math.PI, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        <ToScene name={"Cuisine"} y={-0.0} z={260} />
        <ToScene name={"Balcon"} y={-0.0} z={67} />
    </group>
}

