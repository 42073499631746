import {useEffect, useRef, useState} from "react";
import {showSquare} from "../../config";
import {useCState} from "../../CState";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {sceneKey, screenState} from "../../Scene";
import {Click2d} from "../../Click2d";
import {addDocument, addDocuments} from "../../documents";


export const hasBilanKey = "hasBilanKey";

export const BankBox = () => {
    const [hasBilan, setHasBilan] = useCState(hasBilanKey);

    return (<div
        style={{
            display: 'flex',
            position: "relative",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                position: "absolute",
                width: '1000px',
                height: '731px'
            }}
        >
            <img
                src={require("./bankBox.png").default}
                style={{
                    position: "absolute",
                width: '1000px',
                height: '731px'
                }}
            />
            {!hasBilan && <img
                src={require("./LogBook.png").default}
                style={{
                    position: "absolute",
                    width: '1000px',
                    height: '731px'
                }}
            />}
            {!hasBilan && <Click2d
                style={{
                    left: '400px',
                    top: '200px',
                    width: '360px',
                    height: '300px',
                }}
                onClick={() => {
                    setHasBilan(true);
                    addDocuments(["bilanPdf",
                    "bilanNotes"]);
                }}
            ></Click2d>}

        </div>
    </div>)
}