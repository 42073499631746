import {ReactMagnifier} from "@sandeepv68/react-magnifier";
import {Click2d} from "../../Click2d";
import {useEffect, useRef, useState} from "react";
import {useCState} from "../../CState";
import {AssociateUnlockedKey} from "../Oncle/Oncle";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {sceneKey, screenState} from "../../Scene";
import {slotAnswerKey} from "../Mac/slot";


const width = 857;
const height = 420;

const discWidth = 288;
const discHeight = 288;

function isAnswer(left, right) {
    return ((left % 360 === (360 / 8) * 1)
        && (right % 360 === (360 / 8) * 7))
}

export const Discs = () => {
    const [left, setLeft] = useState(0);
    const [right, setRight] = useState(0);
    const [unlocked, setUnlocked] = useCState(AssociateUnlockedKey);
    const setPage2d = useSetRecoilState(page2dState);
    const [_s,setScreen] = useCState(sceneKey);
    const timeoutId  = useRef(null);
    const [isPrinted, _] = useCState(slotAnswerKey, false);
    // console.log("Discs ", left% 360, right% 360)


    useEffect(() => {
        if (timeoutId .current)
            clearTimeout(timeoutId.current);

        if (!isPrinted) {
            // console.log("too early")
            return;
        }

        if (isAnswer(left, right)) {
            timeoutId.current = setTimeout(() => {
                setUnlocked(true);
                setScreen("Associate");
                setPage2d(null);
            }, 2000);
        }
    },[left, right])

    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                minHeight: height + 'px',
                minWidth: width + 'px',
                position: 'relative',
            }}
        >

            <img
                style={{
                    position: 'absolute',
                    left: '520px',
                    top: '33px',
                    height: discWidth + 'px',
                    width: discHeight + 'px',
                    transform: `rotate(${right}deg)`,
                    transition: 'transform 0.5s ease',
                    cursor: 'pointer'
                }}
                src={require("./disc.png").default}

                onClick={() => {
                    setRight(right + (360 / 8));
                }}/>


            <img
                style={{
                    position: 'absolute',
                    left: '51px',
                    top: '33px',
                    height: discWidth + 'px',
                    width: discHeight + 'px',
                    transform: `rotate(${left}deg)`,
                    transition: 'transform 0.5s ease',
                    cursor: 'pointer'
                }}
                src={require("./disc.png").default}
                onClick={() => {
                    setLeft(left + (360 / 8));
                }}
            />

            <img
                style={{
                    position: 'absolute',
                    height: '38px',
                    width: '539px',
                    left: '163px'
                }}
                src={require("./triangles.png").default}/>
        </div>

    </div>)
}