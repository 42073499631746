import {useEffect, useRef, useState} from "react";

export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};


export const Etirer = () => {

    const mousePosition = useMousePosition();

    const componentRef = useRef()
    // const {width, height} = useContainerDimensions(componentRef);
    const  width = window.innerWidth;
    const  height = window.innerHeight;

    const yRot = -90 + 180 * (mousePosition.x / width)
    const xRot  = -90 + 180 * (mousePosition.y / height)

    return (<div
        ref={componentRef}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            transform: "perspective(600px) rotateX("+xRot+"deg) rotateY("+yRot+"deg)"
        }}

    >
        <img
            style={imgStackStyle}
            src={require("./etirer.png").default}/>
    </div>)
}
const useMousePosition = () => {
    const [
        mousePosition,
        setMousePosition
    ] = useState({x: null, y: null});

    useEffect(() => {
        const updateMousePosition = ev => {
            setMousePosition({x: ev.clientX, y: ev.clientY});
        };

        window.addEventListener('mousemove', updateMousePosition);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return mousePosition;
};

export const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({width: 0, height: 0})

    useEffect(() => {
        const getDimensions = () => ({
            width: myRef.current.offsetWidth,
            height: myRef.current.offsetHeight
        })

        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])

    return dimensions;
};