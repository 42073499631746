import {useCState} from "../../../CState";
import moment from "moment";
import {useInterval} from "../../../Hooks";
import {useState} from "react";

//repondeur
//sms
//Interface GPS

moment.locale('fr')

export const Desktop = ({setStep}) => {
    let bottom = '77px';

    const [gameStarted, _] = useCState("gameStarted");
    const [now, setNow] = useState(null);

    useInterval(() => {
        setNow(Date.now());
    }, 1000);

    let date;
    if (now) {
        let startedDuration = Date.now() - gameStarted;
        date = new Date();
        date.setHours(18, 0, 0);
        date.setFullYear(2030, 10, 30);

        if (!startedDuration)
            startedDuration = 0;
        date.setTime(date.getTime() + startedDuration)
        // console.log("date " + date.getTime())
    }


    let style = {
        color: 'rgba(255, 255, 255, 0.89)',
        fontSize: '30px',
        textAlign: 'center',
        width: '248px',
        position: 'absolute',
        padding: '15px',
        top: '53px',
        left: '40px',
        background: 'rgba(255, 255, 255, 0.18)',
        fontFamily: 'arial',
        borderRadius: '18px'
    };
    return (<div
        style={{
            width: '324px',
            height: '650px',
            position: 'relative',
            backgroundImage: `url(${require("./smartphone.png").default})`
        }}>
        {date && <div style={{
            position: 'absolute',
            top: '25px',
            left: '30px',
            color: '#ffffffe3',
            fontSize: '10px',
            fontFamily: 'arial',
        }}>
            {moment(date).format('HH:mm')}
        </div>}
        {date && <div style={style}>
            {moment(date).utc().format('D MMMM YYYY')}
            <br/>
            {moment(date).format('HH:mm:ss')}
        </div>}
        <img style={{
            height: '60px',
            width: '60px',
            position: 'absolute',
            bottom,
            left: '40px'
        }}
             src={require("./msgIcon.png").default}
             onClick={()=>{
                 setStep("message")
             }}
        />
        <img style={{
            height: '60px',
            width: '60px',
            position: 'absolute',
            bottom,
            left: '131px'
        }}
             src={require("./mapIcon.png").default}
             onClick={()=>{
                 setStep("map")
             }}
        />
        <img style={{
            height: '60px',
            width: '60px',
            position: 'absolute',
            bottom,
            right: '40px'
        }}
             src={require("./callIcon.png").default}
             onClick={()=>{
                 setStep("call")
             }}
        />
    </div>)
}