import React, {useState} from "react";
import {loadC, persistC} from "../../util";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import moment from "moment";
import 'moment/locale/fr'
import {loadTeam, sendPassword, startGame} from "../../server/toApi";
import {useCState} from "../../CState";
import {gameStartedKey} from "../../Chrono";
import {toTimeStr} from "../../server/apiUtil";

moment.locale('fr')


export const Rules = ({nextCb}) => {

    return (<>
            <img src={require("./title.png").default} style={{alignSelf: "center"}}/>
            <p>
                Vous allez commencer un « <i>escape game</i> » en droit des sociétés.<br/>
                Ce jeu reprend les codes des escapes games : les consignes ne sont pas explicites. Vous devez observer
                les éléments qui vous sont présentés et faire des liens entre eux pour avancer dans le jeu. Les
                tentatives pour chaque puzzle sont illimitées et les erreurs ne sont pas pénalisées. Gardez pour vous
                les solutions.<br/>
                <br/>
                Avant de commencer :<br/>
                - Activer le son de votre ordinateur<br/>
                - Prenez de quoi prendre des notes<br/>
                - Garder à portée de main le code des obligations et votre smartphone<br/>
                <br/>
                Ce jeu est chronométré et s'effectue en équipe.<br/>
                Le chronomètre se déclenche pour l’équipe, lorsque la première joueuse ou le premier joueur
                commence.<br/>
                Il s’arrête pour l’équipe, dès que la première joueuse ou le premier joueur a terminé.<br/>
                Chaque indice demandé ajoute 30 minutes au temps de jeu de l'équipe.<br/>
                Votre score final est calculé ainsi: <br/>
                Score final = Durée de la partie + Nombre d'indices demandés X 30 minutes<br/>

                <br/>
                Bonne chance !<br/>
                <button
                    style={{
                        ...btnStyle,
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                    }}
                    onClick={nextCb}
                >Suivant {"➩"}
                </button>
                <br/>
            </p>

            <img src={require("./unige.png").default} style={{alignSelf: "center"}}/>
        </>
    );
}

const btnStyle = {
    background: "black",
    color: "white",
    border: "solid yellow 1px",
    borderRadius: "10px",
    padding: "10px",
    fontSize: "20px",
    cursor: "pointer",
    width: "150px",
    textAlign: "center",
};

const inputStyle = {
    background: "black",
    color: "white",
    border: "solid yellow 1px",
    borderRadius: "10px",
    padding: "10px",
    fontSize: "20px"
};

export const Login = ({nextCb}) => {
    const setPage2d = useSetRecoilState(page2dState);
    const [_1, setGameStartedTime] = useCState(gameStartedKey);
    const [email, setEmail] = useState("");
    // const [email, setEmail] = useState("beauvaisbruno@gmail.com");
    const [team, setTeam] = useState(loadC("team"));
    const [allPlayers, setAllPlayers] = useState(loadC("allPlayers", []));
    const [player, setPlayer] = useState(loadC("player"));
    const [teamErrorMsg, setTeamErrorMsg] = useState();
    const [teamLoading, setTeamLoading] = useState(false);
    const [passwords, setPasswords] = useState(loadC("passwords"));
    const [passwordErrorMsg, setPasswordErrorMsg] = useState();
    const [passwordLoading, setPasswordLoading] = useState(false);
    const [passwordSent, setPasswordSent] = useState(false);
    const [startGameLoading, setStartGameLoading] = useState(false);
    const [startGameErrorMsg, setStartGameErrorMsg] = useState();


    // console.log("team: ", team);
    // console.log("allPlayers: ", allPlayers);
    // console.log("player: ", player);
    // console.log("teamLoading: ", teamLoading);
    // console.log("teamErrorMsg: ", teamErrorMsg);
    // console.log("passwordErrorMsg: ", passwordErrorMsg);
    // console.log("passwordsLoading: ", passwordLoading);
    // console.log("passwordSent: ", passwordSent);

    return (<>
        Votre adresse email :<br/>
        <Flex>

            <input
                style={{
                    ...inputStyle,
                    width: "400px",
                }}
                type={"email"}
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                value={email}
            />
            <button
                style={{
                    ...btnStyle,
                }}
                disabled={teamLoading}
                onClick={() => {
                    setTeamLoading(true);
                    loadTeam(email).then((res) => {
                            // console.log(">loadTeam res: ", res);
                            setTeamLoading(false);
                            if (res.error) {
                                setTeamErrorMsg(res.error);
                                return
                            }
                            setTeam(res.team);
                            setAllPlayers(res.allPlayers);
                            setPlayer(res.player);
                            setTeamErrorMsg(null);
                        }
                    )
                }
                }
            >
                {teamLoading ? "Chargement..." : "Connexion"}
            </button>
            {teamErrorMsg && <div style={{color: "red"}}>{teamErrorMsg}</div>}
        </Flex>
        {team && <>
            <br/>

            <p style={{textAlign: "left"}}>Pour commencer la partie vous devez entrer le mot de passe
                de <u>tous</u> les joueurs de votre équipe :
                {" " + allPlayers.map((p) => p.email).join(", ") + ". "}
                <GameStatus team={team}/>
            </p>
            <button
                style={{
                    ...btnStyle,
                    width: "400px",
                    alignSelf: "center",
                    marginTop: "10px",
                }}
                disabled={passwordLoading}
                onClick={() => {
                    setPasswordLoading(true);
                    sendPassword(player).then((res) => {
                            setPasswordLoading(false);
                            // console.log(">sendPassword res: ", res);
                            if (res.error) {
                                setPasswordErrorMsg(res.error);
                                return
                            }
                            setPasswordSent(true);
                            setPasswordErrorMsg(null);
                        }
                    )
                }}
            >
                {passwordLoading ? "Chargement..." : "Envoyer votre mot de passe par email"}
            </button>
            {passwordSent &&
                <div style={{color: "#63ff63", textAlign: "center"}}>Mot de passe envoyé (vérifiez vos spams)</div>}
            {passwordErrorMsg && <div style={{color: "red", textAlign: "center"}}>{passwordErrorMsg}</div>}

            <br/>
            Mot de passes de tous les joueurs de votre équipe séparés par un espace: <br/>
            <input
                style={{
                    ...inputStyle,
                    width: "100%",
                }}
                type={"text"}
                value={passwords}
                onChange={(e) => {
                    setPasswords(e.target.value);
                }}/>
            {startGameErrorMsg && <div style={{color: "red", textAlign: "center"}}>{startGameErrorMsg}</div>}
            <br/>
            <br/>
        </>}

        <button
            style={{
                ...btnStyle,
                position: "absolute",
                bottom: "20px",
            }}
            onClick={nextCb}
        >{"⇦"} Règles
        </button>
        <button
            disabled={startGameLoading}
            style={{
                ...btnStyle,
                position: "absolute",
                bottom: "20px",
                right: "20px",
                width: "200px",
            }}

            onClick={() => {
                setStartGameLoading(true);
                startGame(player, passwords).then((res) => {
                        setStartGameLoading(false);
                        // console.log(">startGame res: ", res);
                        if (res.error) {
                            setStartGameErrorMsg(res.error);
                            return
                        }
                        const newTeam = res.team
                        // console.log("newTeam: ", newTeam);

                        setTeam(newTeam);
                        persistC("team", newTeam);
                        persistC("allPlayers", allPlayers);
                        persistC("player", player);
                        persistC("passwords", passwords);
                        setStartGameErrorMsg(null);

                        // console.log("gameStartTimeStr: ", toTimeStr(newTeam.gameStartTime));
                        setGameStartedTime(newTeam.gameStartTime);
                        setPage2d(null);
                    }
                )
                ;
            }
            }
        >{startGameLoading ? "Chargement..." : "Commencer le jeu"}
        </button>
    </>);
}

const GameStatus = ({team}) => {
    if (!team.gameStartTime) return <>Le jeu n'a pas encore commencé.</>
    if (!team.gameEndTime) {
        const duration = moment.duration(moment().diff(moment(team.gameStartTime)));
        const durationStr = duration.hours() + "h " + duration.minutes() + "min " + duration.seconds() + "s";
        return <>Le jeu a commencé il y a {durationStr}</>
    }
    const duration = moment.duration(moment(team.gameEndTime).diff(moment(team.gameStartTime)));
    const durationStr = duration.hours() + "h " + duration.minutes() + "min " + duration.seconds() + "s";
    return (< >Le jeu est terminé, durée: {durationStr}</>)
}

export const Welcome = () => {
    const setPage2d = useSetRecoilState(page2dState);
    // const [step, setStep] = useState("login");
    const [step, setStep] = useState("rules");

    let displayed = <Rules nextCb={() => setStep("login")}/>
    if (step === "login")
        displayed = <Login nextCb={() => setStep("rules")}/>

    return (<div
            style={{
                top: 0,
                left: 0,
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}

        >
            <div
                style={{
                    width: "1000px",
                    height: "600px",
                    color: "white",
                    border: "solid white 1px",
                    background: "black",
                    padding: "20px",
                    paddingRight: "5px",
                    borderRadius: "20px",
                    position: "relative",
                }}>
                <div
                    className={"simple-scroll"}
                    style={{
                        overflowY: "auto",
                        height: "calc(600px - 40px)",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "justify",
                        lineHeight: "30px",
                        fontSize: "20px",
                        paddingRight: "10px",
                    }}
                >
                    {displayed}
                </div>
            </div>
        </div>
    )

}

const Flex = ({children, style}) => {
    if (!style) style = {};
    return <div style={{
        display: "flex", alignItems: 'center',
        gap: "10px", ...style
    }}>{children}</div>
}

const U = ({children}) => {
    return <span style={{textDecoration: "underline"}}>{children}</span>
}