import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {To2d, ToScene} from "../../Zone";
import {useEffect} from "react";
import {addClueStep} from "../../clues";

export function Paint1() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./paint1.jpg').default);


    useEffect(() => {
        addClueStep("galerie")
    },[])


    return <group>

        <mesh rotation={[0, Math.PI, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        <ToScene name={"Paint2"} y={-0.5} z={160} />
        <ToScene name={"Front"} y={-0.2} z={-45} />
        <To2d name={"Etirer"} y={-0.2} z={190} />
        <To2d name={"Forms"} y={-0.2} z={217} />
        <To2d name={"Jeans"} y={-0.2} z={260} />
        <To2d name={"Dissout"} y={-0.6} z={75} />
        <To2d name={"Labyrinthe"} y={-0.6} z={15} />

    </group>
}