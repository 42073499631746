import {Click2d} from "../../../Click2d";
import {useState} from "react";
import {useCState} from "../../../CState";
import {CloseInfo, closeInfoDesktopKey} from "./CloseInfo";
import {addDocument, addDocuments} from "../../../documents";

const welcomeStyle = {
    width: '767px',
    height: '581px',
    position: 'relative',
    backgroundImage: `url(${require("./desktop.jpg").default})`
};
let ItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #383838',
    paddingLeft: '26px',
    justifyContent: 'center',
    minHeight: '60px'
};
let oneEmailStyle = {
    color: 'whitesmoke',
    fontFamily: '"Arial"',
    paddingLeft: '11px',
    fontSize: '14px',
    lineHeight: '20px',
};
let senderStyle = {
    color: "whitesmoke",
    fontSize: "14px",
    fontFamily: "Arial",
};
let extraitStyle = {
    color: "#767676",
    fontSize: "14px",
    fontFamily: "Arial",
    paddingTop: '4px'
};
let readColor = '#adadad';

export function AnnaEmail1({style}) {
    return <div style={{...oneEmailStyle, ...style}}>
        <br/>
        Hello,<br/><br/>
J’espère que tout va bien de ton côté. <br/><br/>
De mon côté, j’ai de bien tristes nouvelles.<br/><br/>
Tu te souviens de mon oncle Balthazar ? Celui qui rêvait toujours que je reprenne sa galerie ? Et qui passait sa vie à nous faire deviner des énigmes ?
Il y a un certain temps, il m’avait envoyé un message d’un hôtel à El Chaltén pour me dire qu’il avait enfin pris des vacances, qu’il était sur le point de faire le trek du Fitz Roy en Argentine et qu’il me rendrait visite sur le chemin de retour de l’Europe à Caaguazù. <br/><br/>
Puis, plus de nouvelles de mon oncle et je m’étais dit qu’il avait trouvé trop compliqué de venir me voir à Caaguazù. Il y a quelques jours, je reçois d’abord un appel de la notaire, puis elle m’envoie deux documents. Mon oncle est mort pendant le trek, dans un accident assez dramatique : il est tombé d’un pont en bois au-dessus d’une rivière. Il paraît que j’hérite tout. Je reviendrai à toi pour m’aider avec la succession (je t’envoie déjà ce que j’ai reçu de la notaire).<br/><br/>
Bizzz,<br/><br/>
Annabelle<br/><br/>
        <div style={{borderBottom: "1px solid #383838", width: "100%"}}>&nbsp;</div>
    </div>;
}

export function AnnaEmail2({style}) {
    return <div style={{...oneEmailStyle, ...style}}>
       Hello,<br/><br/>
Pour la succession de mon oncle, tu as vu dans le testament que j’hérite la participation dans sa galerie d’art à Genève (je ne suis pas sûre qu’il y ait d’autres choses, mais je sais qu’elle vaut plusieurs millions). Tu me connais, je ne saurais absolument pas quoi faire d’une galerie d’art à Genève. Ce dont j’ai vraiment besoin pour mon projet d’éco-village ici, c’est de (beaucoup de) sous. Je me suis mise à faire des investigations pour savoir s’il y a des chances que j’en reçoive.<br/><br/>
Une amie d’ici – qui a toujours été super-douée en tout – m’a dit que si cela se passait ici, il faudrait commencer par chercher la forme juridique de la galerie de mon oncle. Elle m’a aussi dit qu’il y aurait peut-être des informations à ce sujet dans un guichet unique (ici cela s’appelle le Système unifié d'ouverture des entreprises, c’est compliqué parce qu’ils ont besoin de tas de documents et tout prend des siècles, mais c’est souvent très utile). Quoi qu’il en soit, je ne sais pas du tout si c’est la même chose en Suisse.<br/><br/>
Mon amie m’a aussi dit qu’il serait déjà utile de se faire une idée du (je cite, sans trop comprendre) « statut juridique actuel de la galerie » pour savoir où on en est a priori. Elle m’a aussi dit que si on se rend compte après coup que, je cite encore toujours sans comprendre vraiment, « les parties ont dérogé à la loi », on pourra toujours ajuster l’appréciation.<br/><br/>
Que penses-tu des pistes proposées par mon amie ? Peux-tu t’en occuper ? Ce serait vraiment méga-top.<br/><br/>
Bizzz,<br/><br/>
Annabelle<br/><br/>

        <div style={{borderBottom: "1px solid #383838", width: "100%"}}>&nbsp;</div>
    </div>;
}

export function AnnaEmail3({style}) {
    return <div style={{...oneEmailStyle, ...style}}>

       Hello,<br/><br/>
Je t’avais parlé de la succession de mon oncle. Il s'y passe vraiment des trucs bizarres!<br/><br/>
J’ai essayé de prendre contact avec la galerie et c’est vraiment très très chelou. Aucune réponse à mes mails. A chaque fois que je téléphone (et comme tu peux t’imaginer ce n’est pas vraiment très facile d’avoir du réseau quand on est en pleine forêt, sans compter le décalage horaire), je dis mon nom et on me raccroche au nez.<br/><br/>

Et tout cela alors qu’il y a des enquêtes en cours pour voir si le décès de mon oncle était vraiment accidentel. Il semble qu’ils doutent entre une très forte bourrasque (c’est le Fitz Roy après tout) et quelqu’un qui l’aurait poussé par-dessus la barrière.<br/><br/>

Du coup, j’ai repris le message que mon oncle m’avait envoyé depuis El Chaltén et je commence à mieux comprendre certains détails que j’avais rangés dans la case paranoïa. Mon oncle m’avait écrit qu’il avait caché des indices dans la galerie pour que je puisse savoir à quoi j’ai droit et comment faire pour le recevoir.<br/><br/>

Je sais que je ne devrais pas te demander ça, vu tes études et la profession que tu vises, mais pourrais-tu aller enquêter sur place en catimini ? Je ne peux absolument pas quitter le pays en ce moment pour des raisons de visa. De toute manière, je pense que tu serais mieux équipé*e que moi car mon oncle était un spécialiste des blagues juridiques ; sa grande frustration était de ne pas avoir fait des études de droit. Il me disait toujours qu’il allait à la galerie le dimanche après-midi, quand il était tranquille, après le passage de l’équipe de nettoyage qui termine à midi et avant la ronde des Securitas à six heures du soir.<br/><br/>

Dis-moi que ça joue, j’ai vraiment trop besoin de toi.<br/><br/>

Bizzz,<br/><br/>
        Annabelle<br/><br/>
        <div style={{borderBottom: "1px solid #383838", width: "100%"}}>&nbsp;</div>
        <br/>
        {/*<b>From</b>: Oncle Balthazar<br/>*/}
        {/*<b>To</b>: Annabelle <br/>*/}
        {/*<b>Subject</b>: (...A compléter)<br/>*/}
        {/*(Email de l'oncle A compléter...)*/}
        <br/><br/>
    </div>;
}

export const OneEmail = ({setEmail, email}) => {
    return <>
        <img
            className={"fade"}
            src={require("./oneEmail.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '30px',
                top: '30px',
                width: '706px',
                height: '490px',
            }}
        />
        <div
            style={{
                position: 'absolute',
                left: '260px',
                top: '218px',
                width: '472px',
                height: '275px',
                // backgroundColor: 'rgb(8, 255, 0)',
                backgroundColor: '#262626',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll'
            }}
        >

            {email === "one" && <div style={{display: "flex", gap:"10px", marginLeft:"10px"}}> <img
                onClick={() => {
                    addDocument("deces")
                }}
                src={require("./decesIcon.png").default}
                style={{
                    cursor: "pointer",
                    width: '200px',
                }}
            />
                <img
                onClick={() => {
                    addDocument("testament")
                }}
                src={require("./testamentIcon.png").default}
                style={{
                    cursor: "pointer",
                    width: '200px',
                }}
            />
            </div>}
            {email === "one" && <AnnaEmail1 style={oneEmailStyle}/>}
            {email === "two" && <AnnaEmail2 style={oneEmailStyle}/>}
            {email === "three" && <AnnaEmail3 style={oneEmailStyle}/>}
        </div>
    </>
}
export const Emails = ({setEmail}) => {
    return <>
        <img
            src={require("./listEmails.png").default}
            className={"fade"}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '30px',
                top: '30px',
                width: '706px',
                height: '490px',
            }}
        />
        <div
            style={{
                position: 'absolute',
                left: '260px',
                top: '204px',
                width: '472px',
                height: '289px',
                backgroundColor: '#262626',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
            }}
        >
            <div style={ItemStyle}>
                <div style={senderStyle}>Léo S
                </div>
                <div style={extraitStyle}>
                    Joyeux anniversaire !<br/>
                </div>
            </div>
            <div style={ItemStyle}>
                <div style={senderStyle}>David UNIGE
                </div>
                <div style={extraitStyle}>
                    Joyeux anniversaire !<br/>
                </div>
            </div>
            <div style={ItemStyle}>
                <div style={senderStyle}>Aurélie
                </div>
                <div style={extraitStyle}>
                    Happy birthday !<br/>
                </div>
            </div>
            <div style={ItemStyle}>
                <div style={senderStyle}>Extraordinary Prize!
                </div>
                <div style={extraitStyle}>
                    To claim your prize, we need your credit card number...<br/>
                </div>
            </div>
            <div style={ItemStyle}>
                <div style={senderStyle}>Dr. Bakare Tunde
                </div>
                <div style={extraitStyle}>
                    I am Nigerian Prince, I need your account to transfer...<br/>
                </div>
            </div>

            <div style={ItemStyle} className={"clickableEmailList"} onClick={() => {
                setEmail("three")
                addDocument("annaEmail3")
            }}>
                <div style={senderStyle}>Annabelle
                </div>
                <div style={extraitStyle}>
                    Hello, Il se passe vraiment des trucs bizarres dans la succession...<br/>
                </div>
            </div>
            <div style={{...ItemStyle}} className={"clickableEmailList"} onClick={() => {
                setEmail("two");
                addDocument("annaEmail2")
            }}>
                <div style={{...senderStyle}}>Annabelle
                </div>
                <div style={extraitStyle}>
                    Hello, Pour la succession de mon oncle, il paraît que tout...<br/>
                </div>
            </div>
            <div style={ItemStyle} className={"clickableEmailList"} onClick={() => {
                setEmail("one")
                addDocument("annaEmail1")
            }}>
                <img src={require("./attached.png").default} style={{position: 'absolute', marginLeft: '-20px'}}/>
                {/*<div style={{...senderStyle, color:readColor}}>Annabelle*/}
                <div style={{...senderStyle}}>Annabelle
                </div>
                <div style={extraitStyle}>
                    Hello, J’espère que tout va bien de ton côté...<br/>
                </div>
            </div>
        </div>
    </>
}


export const ListEmails = ({setStep}) => {


    const [closeInfo, setCloseInfo] = useCState(closeInfoDesktopKey);
    const [email, setEmail] = useState(null);
    // console.log("email", email)
    return (<div
        style={{...welcomeStyle}}>
        {!closeInfo && <CloseInfo></CloseInfo>}


        {email === null && <Emails setEmail={setEmail}/>}
        {email !== null && <OneEmail setEmail={setEmail} email={email}/>}
        <Click2d
            style={{
                left: '695px',
                top: '31px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => {
                setStep("welcome");
                setCloseInfo(true);
            }}
        ></Click2d>

        <Click2d
            style={{
                left: '31px',
                top: '141px',
                width: '223px',
                height: '308px',
            }}
            onClick={() => setEmail(null)}
        ></Click2d>
    </div>)
}
