import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene} from "../../../Zone";
import {useEffect, useState} from "react";
import {addItem, hasItem} from "../../../BackPack";

export function Balcon() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./balcon.jpg').default);

    return <group rotation={[0, 0.2* Math.PI, 0]}>

        <mesh rotation={[0, Math.PI*1, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
            <ToScene name={"Salon"} y={-0.2} z={248} />
            <ToScene name={"Chambre"} y={-0.2} z={150} />
        </mesh>
    </group>
}

