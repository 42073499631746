import {ReactMagnifier} from "@sandeepv68/react-magnifier";
import {Click2d} from "../../Click2d";
import {useEffect, useRef, useState} from "react";
import {useCState} from "../../CState";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {sceneKey, screenState} from "../../Scene";
import {arrayEquals} from "../../util";
import {completeResponse} from "../../config";
import {answeredKey, oncleEmailAnsweredKey} from "../../scene1/2d/smartphone/Smartphone";
import {addClueStep} from "../../clues";


const width = 411;
const height = 700;

// 2, 8, 9, 10,
function isAnswer(enableds) {
    return arrayEquals(enableds,
        [false, // 1
            true, // 2
            false, // 3
            false, // 4
            false, // 5
            false, // 6
            false, // 7
            true, // 8
            true, // 9
            true, // 10
            false, // 11
            false, // 12
        ]);
}

export const factsUnlockedKey = "factsUnlockedKey"
export const Calc = () => {
    const [enableds, setEnableds] = useState(completeResponse ? [false, // 1
            true, // 2
            false, // 3
            false, // 4
            false, // 5
            false, // 6
            false, // 7
            true, // 8
            true, // 9
            false, // 10
            false, // 11
            false, // 12
        ]
        : new Array(12).fill(false));
    const [unlocked, setUnlocked] = useCState(factsUnlockedKey);
    const [_, setAnsweredOncle] = useCState(oncleEmailAnsweredKey);
    const setPage2d = useSetRecoilState(page2dState);
    const timeoutId = useRef(null);
    // console.log("enabled ", enableds)

    useEffect(() => {
        if (timeoutId.current)
            clearTimeout(timeoutId.current);
        if (isAnswer(enableds)) {
            // console.log("isAnswer ", enableds)
            timeoutId.current = setTimeout(() => {
                setUnlocked(true);
                addClueStep("calc");
                setAnsweredOncle(false);
                setTimeout(() => {
                  setPage2d("BankBox")
                }, 2000);
            }, 2000);
        }
    }, [enableds])

    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <img
            style={{
                position: 'absolute',
                height,
                width,
            }}
            src={require("./calc.png").default}/>
        <img
            className={unlocked ? "blink-image" : ""}
            style={{
                position: 'absolute',
                height,
                width
            }}
            src={unlocked ? require("./calcSuccess.png").default : require("./calcLock.png").default}/>
        <div
            style={{
                minHeight: height + 'px',
                minWidth: width + 'px',
                position: 'relative',
            }}
        >{
            enableds.map((e, i) => {
                return (<div
                    key={i}
                    style={{
                        position: 'absolute',
                        left: 35 + (i % 3) * 120 + 'px',
                        top: 218 + Math.floor(i / 3) * 110 + 'px',
                        height: '60px',
                        width: '80px',
                        backgroundColor: '#1d1716',
                        borderRadius: '5%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '30px',
                        color: 'white',
                        cursor: 'pointer',
                        boxShadow: 'rgb(0 0 0) 2px 2px 3px'
                    }}
                    onClick={() => {
                        let newEnabled = [...enableds];
                        newEnabled[i] = !newEnabled[i];
                        setEnableds(newEnabled);
                    }}
                >
                    #{i + 1}
                </div>)
            })

        }
            {
                enableds.map((enabled, i) => {
                    return (<img
                        key={i}
                        style={{
                            position: 'absolute',
                            left: 60 + (i % 3) * 120 + 'px',
                            top: 285 + Math.floor(i / 3) * 110 + 'px',
                            height: '30px',
                            width: '30px',
                            // backgroundImage: "radial-gradient(" + color + ", " + color2 + ")",
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '30px',
                            color: 'white',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            let newEnabled = [...enableds];
                            newEnabled[i] = !newEnabled[i];
                            setEnableds(newEnabled);
                        }}
                        src={enabled ? require("./yes.png").default : require("./no.png").default}
                    >
                    </img>)
                })

            }


        </div>

    </div>)
}