import React, {useState} from "react";
import {useInterval} from "./Hooks";
import Cookies from "js-cookie";
import {addDocuments, documentsKey} from "./documents";
import {cStateKey, useCState} from "./CState";
import {siteGaleAvailableKey} from "./scene1/2d/desktop/Desktop";
// import {Page} from "@react-pdf/renderer";
// import { PDFViewer } from '@react-pdf/renderer';
// import { Document, Page } from '@react-pdf';
// import css
import './Classeur.css';
import {Helmet} from "react-helmet";
import * as PropTypes from "prop-types";
import {AnnaEmail1, AnnaEmail2, AnnaEmail3} from "./scene1/2d/desktop/Emails";
import {showAllDocs} from "./config";
import {Clues} from "./clues";


export const docsObj = [
    // Chapter 1
    [
        {
            important: true,
            name: "annaEmail2",
            label: "Email d'Annabelle 2",
            comp: AnnaEmail2,
        },
        {
            important: true,
            name: "annaEmail1",
            label: "Email d'Annabelle 1",
            comp: AnnaEmail1,
        },
        {
            name: "annaEmail3",
            label: "Email d'Annabelle 3",
            comp: AnnaEmail3,
        },
        {
            name: "deces",
            label: "Annonce de décès",
            frame: "Acte_deces international_Dujardin.pdf",
        },
        {
            name: "testament",
            label: "Testament",
            frame: "Testament.pdf",
        },
        // {
        //     name: "codeDesObligations",
        //     label: "Code des Obligations",
        //     link: "https://www.fedlex.admin.ch/eli/cc/27/317_321_377/fr"
        // },
        {
            name: "rdc",
            label: "Registre du commerce de Genève",
            link: "https://www.ge.ch/recherche-entreprises-dans-registre-du-commerce-geneve"
        },
        {
            name: "galerieWeb",
            label: "Site web de la galerie",
            frame: "galerie/index.html",
        },
        {
            name: "articleGalerie",
            label: "Article sur Balthazar",
            frame: "doc/Article sur Batlhazar.pdf",
        },
    ],
    // Chapter 2
    [],
    // Chapter 3
    [
        {
            important: true,
            name: "contrat",
            label: "Contrat",
            frame: "doc/Contrat.pdf",
        },
        {
            name: "avenant1",
            label: "Avenant 1",
            frame: "doc/avenant1.pdf",
        },
        {
            name: "avenant2",
            label: "Avenant 2",
            frame: "doc/avenant2.pdf",
        },
        {
            name: "avenant3",
            label: "Avenant 3",
            frame: "doc/avenant3.pdf",
        },
        {
            name: "avenant4",
            label: "Avenant 4",
            frame: "doc/avenant4.pdf",
        },
        {
            name: "avenant5",
            label: "Avenant 5",
            frame: "doc/avenant5.pdf",
        }

    ],
    // Chapter 4
    [
        {
            important: true,
            name: "projetDissolution",
            label: "Action en dissolution",
            frame: "doc/Action en dissolution.pdf",
        },
        {
            name: "poeme",
            label: "Poème",
            frame: "doc/Poème.pdf",
        },
        {
            name: "carteTouristique",
            label: "Carte touristique",
            frame: "doc/Carte touristique.pdf",
        },
        {
            name: "04_02",
            label: "Bois d'ébène",
            frame: "doc/04_02_Bois_d'ebene.pdf",
        },
        {
            name: "04_03",
            label: "Correspondance ami-es",
            frame: "doc/04_03_correspondance ami-es.pdf",
        },
        {
            name: "04_04",
            label: "DeVito",
            frame: "doc/04_04_DeVito.pdf",
        },
        {
            name: "04_05",
            label: "Goodies SA",
            frame: "doc/04_05_Goodies SA.pdf",
        },
        {
            name: "04_06",
            label: "MADgallery",
            frame: "doc/04_06_MADgallery_new.pdf",
        },
        {
            name: "04_07",
            label: "Swift",
            frame: "doc/04_07_Swift.pdf",
        },
        {
            name: "04_08",
            label: "Vers l'avant",
            frame: "doc/04_08_Vers l'avant.pdf",
        },
        {
            name: "04_09",
            label: "Voyage Affaires",
            frame: "doc/04_09_Voyage Affaires.pdf",
        }
    ],
    // Chapter 5
    [
        // {
        //     important: true,
        //     name: "bilanXlsx",
        //     label: "Bilan Art et Image SNC Spreadsheet EXCEL",
        //     link: "doc/Bilan 3e trimestre 2030.xlsx",
        // },
        {
            important: true,
            name: "bilanPdf",
            label: "Bilan Art et Image SNC",
            frame: "doc/Bilan 3e trimestre 2030.pdf",
        },
        {
            important: true,
            name: "bilanNotes",
            label: "Maître M signé",
            frame: "doc/Maître M signé.pdf",
        },
        {
            name: "msgAnna2",
            label: "Message d'Annabelle 2",
            sound: "doc/annabelleMessage2.mp3",
        },
        {
            name: "05_01",
            label: "Opposition Avenant 5",
            frame: "doc/05_01_Opposition Avenant 5.pdf",
        },
    ],
    // Chapter 6
    [
        {
            name: "msgAnna3",
            label: "Message d'Annabelle 3",
            sound: "doc/annabelleMessage3.mp3",
        },
        {
            name: "liquidateurPhoto",
            label: "Photo du liquidateur",
            frame: "liquidateurPhoto.pdf",
        },
        {
            name: "billet",
            label: "Billet d'avion",
            frame: "billet.pdf",
        }
    ]
]

export const docsMap = {};
docsObj.forEach((chapterLinks, index) => {
    chapterLinks.forEach((doc) => {
        docsMap[doc.name] = doc;
    });
});

const aStyle = {
    display: "flex",
    marginBottom: "10px",
    fontSize: "20px",
    color: "blue",
    cursor: "pointer",
    gap: "10px",
    alignItems: "baseline",
};


export const Classeur = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const showAllDocsParam = urlParams.get('all');


    const initDocuments = [];
    if (showAllDocs || showAllDocsParam) {
        docsObj.forEach((chapterLinks) => {
                chapterLinks.forEach((doc) => {
                    initDocuments.push(doc.name);
                });
            }
        )
    }


    const [documents, setDocuments] = useState(initDocuments);
    const [current, setCurrent] = useState(null);
    // console.log("documents: " + documents);


    useInterval(() => {
        if (showAllDocs || showAllDocsParam)
            return;
        let jsonStr = Cookies.get(documentsKey);
        // console.log("jsonStr: " + jsonStr);
        let newDocuments = []
        if (jsonStr)
            newDocuments = JSON.parse(jsonStr);
        if (newDocuments.length !== documents.length) {
            // console.log("setDocuments: " + newDocuments);
            setDocuments(newDocuments);
        }
    }, 1000);

    const rows = [];

    [...docsObj].reverse().forEach((chapterLinks, index) => {
        // console.log("chapterLinks", chapterLinks)

        if (chapterLinks.reduce((acc, doc) => acc || documents.includes(doc.name), false))
            rows.push(<h2 key={index}>Chapitre {docsObj.length - index}</h2>)

        chapterLinks.forEach((doc, index) => {

                if (documents.includes(doc.name)) {
                    if (doc.sound) {
                        rows.push(<div key={index + "-" + doc.name}>
                            <div style={{
                                display: "flex",
                                fontSize: "20px",
                                color: "blue",
                                gap: "10px",
                                alignItems: "baseline",
                            }}>- {doc.label}</div>
                            <audio controls style={{
                                width: "100%",
                                marginBottom: "5px",
                                padding: "10px",

                            }}>
                                <source src={doc.sound} type="audio/mpeg"/>
                            </audio>
                        </div>)

                    } else
                        rows.push(<div key={index + "-" + doc.name}
                                       className={"hover"}
                                       style={{
                                           ...aStyle,
                                           ...(index === 0 ? {fontWeight: "bold"} : {}),
                                       }}
                                       onClick={() => {
                                           // console.log("doc", doc)
                                           if (doc.link)
                                               window.open(doc.link, doc.name);
                                           else
                                               setCurrent(doc)
                                       }}
                        >
                            {doc.important && <Important/>}
                            {!doc.important && <div>-</div>}
                            <div> {doc.label}</div>
                            {/*<Icon/>*/}
                        </div>)
                }
            }
        )
    });

    let viewing = null;
    if (current) {
        if (current.frame)
            viewing = <iframe
                src={current.frame}
                style={{
                    width: "80%",
                    height: '100vh',
                    overflowY: 'hidden',
                }}
            />
        if (current.comp)
            viewing = <div
                style={{
                    width: "80%",
                    height: '100vh',
                    overflowY: 'auto',
                    // backgroundColor: "black",
                    padding: "20px",
                }}
            >
                <current.comp style={{
                    fontSize: "20px",
                    textAlign: "justify",
                    color: "black",
                }}/>
            </div>
        if (current.compFn)
            viewing = <div
                style={{
                    width: "80%",
                    height: '100vh',
                    overflowY: 'auto',
                    // backgroundColor: "black",
                    padding: "20px",
                }}
            >
                {current.compFn()}
            </div>

    }


    return (<div style={{
        // backgroundColor: 'white',
        backgroundImage: `url(${require("./cartoon.png").default})`,
        paddingLeft: '20px',
        paddingBottom: '40px',
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100vh',
        width: '100vw',
        zIndex: 1000,
        display: 'flex',
    }}>
        <Helmet>
            <title>Porte documents</title>
            <link rel="icon" type="image/png" href="/folder.png"/>
        </Helmet>
        <div className={"redScroll"} style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflowY: 'auto',
        }}>
            <h1>Classeur</h1>
            <div key={"regles"} style={aStyle} onClick={() => {
                setCurrent(rules);
            }}>
                - Règles du jeu
            </div>
            <div key={"clues"} style={aStyle} onClick={() => {
                setCurrent(clues);
            }}>
                - Indices
            </div>
            {/*<a key={"indice"} style={aStyle}>*/}
            {/*    - Demander un indice*/}
            {/*</a>*/}
            {rows}
        </div>
        {viewing}
    </div>)

}


const clues = {
    comp: Clues,
}


const rules = {
    comp: () => <div style={{
        fontSize: "20px",
        textAlign: "justify",
        color: "black",
        lineHeight: "30px",
        width: "80%",
    }}>
        <h2>Règles du jeu</h2>
        <p>Ce jeu reprend les codes des escapes games : les consignes ne sont pas explicites. Vous devez observer les
            éléments qui vous sont présentés et faire des liens entre eux pour avancer dans le jeu. Les tentatives pour
            chaque puzzle sont illimitées et les erreurs ne sont pas pénalisées.<br/>
            <br/>
            Avant de commencer :<br/>
            - Activer le son de votre ordinateur<br/>
            - Prenez de quoi prendre des notes<br/>
            - Garder à portée de main le code des obligations et votre smartphone<br/>
            <br/>
            Ce jeu est chronométré et s'effectue en équipe.<br/>
            Le chronomètre se déclenche pour l’équipe, lorsque la première joueuse ou le premier joueur commence.<br/>
            Il s’arrête pour l’équipe, dès que la première joueuse ou le premier joueur a terminé.<br/>
            Chaque indice demandé ajoute 30 minutes au temps de jeu de l'équipe.<br/>
            Votre score final est calculé ainsi: <br/>
            Score final = Durée de la partie + Nombre d'indices demandés X 30 minutes<br/>
            <br/>
            Bonne chance !<br/>
            <br/>
        </p>
    </div>
}


const Important = () => (<img
    style={{
        width: "15px",
        height: "15px",
        // marginRight: "10px",
    }
    }
    src={require("./important.png").default}></img>);
const Icon = () => (<img
    style={{
        width: "15px",
        height: "15px",
        // marginLeft: "10px",
    }
    }
    src={require("./openIcon.png").default}></img>);