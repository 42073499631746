import Cookies from "js-cookie";
import {cStateKey} from "./CState";
import {setToast} from "./Toast";
import {docsMap, docsObj} from "./Classeur";

export const documentsKey = "documentsKey"

export const addDocument = (documentName) => {
    addDocuments([documentName])
}
export const addDocuments = (documentNames) => {

    let count = 0;
    let nbDocuments = documentNames.length;

    let jsonStr = Cookies.get(documentsKey);
    // console.log("jsonStr: "+jsonStr);
    let documents = []
    if (jsonStr)
        documents = JSON.parse(jsonStr);

    // console.log("documents: " + documents);

    documentNames.forEach(documentName => {
        if (!documents.includes(documentName)) {
            count++;

            documents.push(documentName)
        }
    });

    const labels = [];
    // console.log("docsMap: " , docsMap)
    documentNames.forEach(documentName => {
        const label = docsMap[documentName]?.label;
        if (label)
            labels.push(label);
    });
    // console.log("labels: " + labels);

    if (count > 0)
        setToast(count + " document" + (count > 1 ? "s" : "") + " ajouté" + (count > 1 ? "s" : "") + " à votre classeur: " + labels.join(", "));

    if (count === 0) {
        if (nbDocuments === 1)
            setToast("Ce document est déjà dans votre classeur: " + labels[0]);
        else
            setToast("Ces documents sont déjà dans votre classeur: " + labels.join(", "));
    }

    Cookies.set(documentsKey, JSON.stringify(documents));

}