import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickDocs, To2d, ToScene} from "../../Zone";

export function Remise() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./remise.png').default);


    return <group>

        <mesh rotation={[0, Math.PI, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        <ToScene name={"Associate"} y={-0.4} z={-90} />
        <To2d name={"Barrillet"} y={-0.85} z={89} />
        <ClickDocs y={-0.3} z={-75} docs={["catalogue.pdf"]}/>
        <To2d name={"Sonate"} y={-0.5} z={-55} />
        <To2d name={"Cross"} y={-0.6} z={245} />
        {/*<To2d name={"Tomate"} y={-0.65} z={225} />*/}

    </group>
}