import {useCState} from "../../../CState";
import moment from "moment";
import {useAudio, useInterval} from "../../../Hooks";
import {useEffect, useState} from "react";
import {showSquare} from "../../../config";
import {Click2d} from "../../../Click2d";
import {useSetRecoilState} from "recoil";
import {sceneKey, screenState} from "../../../Scene";
import {page2dState} from "../../../Page2d";

export const Map = ({setStep}) => {

    const [now, setNow] = useState(null);
    const [address, setAddress] = useCState("smartphoneAddress","");
    const [addressOk, setAddressOk] = useState(null);
    const [success, setSuccess] = useState(false);
    const [gameStarted, _] = useCState("gameStarted");
    const [_s,setScreen] = useCState(sceneKey);
    const setPage2d = useSetRecoilState(page2dState);

    useInterval(() => {
        setNow(Date.now());
    }, 1000);

    // console.log("playing", playing);

    let date;
    if (now) {
        const startedDuration = Date.now() - gameStarted;
        date = new Date();
        date.setHours(18, 0, 0);
        // date.setTime(date.getTime()+startedDuration)
        date.setTime(date.getTime() + startedDuration)
        // console.log("date " + date.getTime())
    }

    return (<div
        style={{
            width: '324px',
            height: '650px',
            position: 'relative',
            backgroundImage: `url(${require("./map.png").default})`
        }}>
        {date && <div style={{
            position: 'absolute',
            top: '25px',
            left: '30px',
            color: '#ffffffe3',
            fontSize: '10px',
            fontFamily: 'arial',
        }}>
            {moment(date).format('HH:mm')}
        </div>}

        <input
            onChange={e => setAddress(e.target.value)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    setAddressOk(address);
                    if (address.toLowerCase().includes("rechtsanwalt"))
                        setSuccess(true);
                    else
                        setSuccess(false);
                }
            }}
            value={address}
            style={{
                position: 'absolute',
                top: '57px',
                left: '79px',
                width: '216px',
                height: '30px',
                color: 'rgb(0 0 0 / 65%)',
                fontSize: '17px',
                fontFamily: 'arial',
                border: '0',
                outline: "none"
            }}
            type={"text"} placeholder={"Trouver une adresse"}></input>

        {addressOk && !success &&
            <iframe
                style={{
                    position: 'absolute',
                    top: '106px',
                    left: '19px',
                    width: '287px',
                    height: '485px',
                    border: '0px',
                    background: 'white'
                }}
                width="287" height="485"
                src={"https://www.google.com/maps/embed/v1/search?q=" + encodeURI(addressOk) + "&key=AIzaSyDEqV8uybOgzjnHSZ-S5KHWwPzq0857MV4"}>
            </iframe>
        }
        {success && <img
            style={{
                position: 'absolute',
                top: '106px',
                left: '19px',
                width: '287px',
                height: '485px',
                border: '0px',
                background: 'white'
            }}
            src={require("./mapArtImage.png").default}
        />}
        {success && <Click2d
            style={{
                left: '98px',
                top: '536px',
                width: '134px',
                height: '46px',
            }}
            onClick={() => {
                setPage2d(null);
                setScreen("Front");
            }}
        ></Click2d>}
        <div
            onClick={() => {
                setStep("desktop");
            }}
            style={{
                position: 'absolute',
                bottom: '25px',
                left: '17px',
                width: '290px',
                height: '32px',
                // backgroundColor: 'yellow'
                backgroundColor: showSquare ? "yellow": "transparent",
            }}>
        </div>
    </div>)
}