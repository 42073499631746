import {Click2d} from "../../Click2d";
import {addDocuments} from "../../documents";

export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};

const width = 800;
const height = 572;

export const Contract = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                minHeight: height+'px',
                minWidth: width+'px',
                position: 'relative',
            }}
        >
            <img
                style={{
                    height:  height+'px',
                    width: width+'px',
                }}
                src={require("./contract.png").default}/>
            <Click2d
                style={{
                    left: '50px',
                    top: '50px',
                    width: '550px',
                    height: '430px',
                }}
                onClick={() => {
                    // console.log("click")
                    addDocuments(["contrat"])
                }}
            ></Click2d>
            {/*<Click2d*/}
            {/*    style={{*/}
            {/*        left: '602px',*/}
            {/*        top: '50px',*/}
            {/*        width: '150px',*/}
            {/*        height: '200px',*/}
            {/*    }}*/}
            {/*    onClick={() => {*/}
            {/*        console.log("click")*/}
            {/*        window.open("/polaroid.png")*/}
            {/*    }}*/}
            {/*></Click2d>*/}
        </div>
    </div>)
}