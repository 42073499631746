
export const closeInfoDesktopKey = "closeInfoDesktopKey";

export const CloseInfo = () => {
    return <div className={"blink-image"} style={{
        position: 'fixed',
        top: '10px',
        left: '0',
        width: '100%',
        zIndex: '4',
        textAlign: 'center',
        fontSize: '1.5rem',
        color: 'yellow'
    }}>
        {"Click the X to close the window"}
    </div>
}