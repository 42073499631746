
export const Frigo = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <img style={{
            maxHeight: '100vh',
            maxWidth: '100vw',
        }}
            src={require("./frigo.jpg").default}/>
    </div>)
}