import {
    useFrame,
    useThree,
} from "@react-three/fiber";
import React from "react";
import { useRef, useEffect, useLayoutEffect} from 'react'
import {Euler, MathUtils, Quaternion, Vector3} from "three";
import {useRecoilState} from "recoil";
import {gyroAvailableState, gyroEnabledState } from "./App";
import {isLand} from "./util";

const PI_2 = Math.PI / 2;
const minPolarAngle = 0;
const maxPolarAngle = Math.PI;


function App() {

    const camRef = useRef();
    const lastMouse = useRef({x: null, y: null});
    const destEuler = useRef(null);
    const down = useRef(false);
    const fov = useRef(75);
    const lastDistance = useRef(null);
    const orientationCapture = useRef(true);
    const movFactor = useRef(0.002);

    const {set,size} = useThree(({set,size}) => ({  set,size }));


    const [gyroEnabled, setGyroEnabled] = useRecoilState(gyroEnabledState);
    const [gyroAvailable, setGyroAvailable] = useRecoilState(gyroAvailableState);
    const gyroEnabledRef = useRef(gyroEnabled);

    useEffect(()=> {
        if (!isLand())
            movFactor.current = 0.005;
    },[]);


    useEffect(()=> {
        gyroEnabledRef.current = gyroEnabled;
    },[gyroEnabled]);


    useLayoutEffect(() => {
        if (camRef.current) {
            camRef.current.aspect = size.width / size.height
            camRef.current.updateProjectionMatrix()
        }
    }, [size])

    useLayoutEffect(() => {
        set({camera: camRef.current})
    }, [set])

    useFrame(() => {
        if (destEuler.current && camRef.current) {
            const destQuaternion = new Quaternion().setFromEuler(destEuler.current);
            camRef.current.quaternion.slerp(destQuaternion, 0.1);
        }
    })


    useEffect(() => {

        const getXY = (event, lastMouse) => {
            let mouse;
            if (event.clientX)
                mouse = {x: event.clientX, y: event.clientY};
            if (event.touches)
                mouse = {x: event.touches[0].clientX, y: event.touches[0].clientY};
            // useFrame(({mouse}) => {
            // console.log("mouse :", mouse)

            if (lastMouse.current && lastMouse.current.x && lastMouse.current.y) {
                try {
                    const movementY = (lastMouse.current.y - mouse.y);
                    const movementX = (lastMouse.current.x - mouse.x);
                    lastMouse.current = {...mouse};
                    return {movementX, movementY};
                }catch(error){
                    console.error("getXY :",error)
                }
            }
            lastMouse.current = {...mouse};
            return {movementX: null, movementY: null};
        }


        const onZoom = (delta) => {
            if (camRef.current) {
                fov.current += delta * 0.01;
                fov.current = Math.max(45, Math.min(100, fov.current));
                camRef.current.fov = fov.current;
                camRef.current.updateProjectionMatrix();
                // console.log("fov cam:", camRef.current.fov)
                // console.log("fov :", fov.current)
            }
        }

        const onPinch = (e) => {
            const distance = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
            if (lastDistance.current) {
                onZoom((lastDistance.current - distance) * 10);
            }
            lastDistance.current = distance;
        }
        const onMove = (event) => {
            // console.log("onMove down.current:",down.current, "onPinch",event.touches && event.touches.length === 2)
            if (!down.current)
                return;

            if (event.touches && event.touches.length === 2) {
                onPinch(event);
                return;
            }
            setGyroEnabled(false)

            const {movementX, movementY} = getXY(event, lastMouse);
            if (!movementX)
                return;
            if (!destEuler.current)
                destEuler.current = new Euler(0, 0, 0, 'YXZ').setFromQuaternion(camRef.current.quaternion);
            destEuler.current.y -= movementX * movFactor.current;
            destEuler.current.x -= movementY * movFactor.current;
            destEuler.current.x = Math.max(PI_2 - maxPolarAngle, Math.min(PI_2 - minPolarAngle, destEuler.current.x));
        };

        const onDown = (event) => {
            down.current = true;
        }
        const onUp = (event) => {
            // console.log("onUp event :", event)
            down.current = false;
            lastMouse.current = {x: null, y: null};
            lastDistance.current = null;
        }

        window.addEventListener('touchmove', onMove, false);
        window.addEventListener('mousemove', onMove, false)

        window.addEventListener('touchstart', onDown, false);
        window.addEventListener('touchend', onUp, false);
        window.addEventListener('touchcancel', onUp, false);
        window.addEventListener('mousedown', onDown, false);
        window.addEventListener('mouseup', onUp, false);

        window.addEventListener('wheel', (event) => {

            let delta = event.deltaY;
            onZoom(delta);
        }, false);

        if (window.DeviceOrientationEvent) {
            window.addEventListener("deviceorientation", (event) => {
                if (!event.alpha)
                    return;
                if (!orientationCapture.current)
                    return;

                setGyroAvailable(true)
                if (!gyroEnabledRef.current)
                    return;
                const {alpha, beta, gamma} = {
                    alpha: MathUtils.degToRad(event.alpha),
                    beta: MathUtils.degToRad(event.beta),
                    gamma: MathUtils.degToRad(event.gamma),
                };
                // console.log({alpha, beta, gamma});

                if (!destEuler.current)
                    destEuler.current = new Euler(0, 0, 0, 'YXZ').setFromQuaternion(camRef.current.quaternion);

                const quaternion = new Quaternion().setFromEuler(destEuler.current);
                quaternion.setFromEuler(new Euler(beta, alpha, -gamma, 'YXZ'));
                quaternion.multiply(new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)));
                quaternion.multiply(new Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), -window.screen.orientation.angle));

                destEuler.current = new Euler(0, 0, 0, 'YXZ').setFromQuaternion(quaternion);

            }, true);

        }
    }, []);

    // const ref = useRef();
    return (
        <perspectiveCamera
            ref={camRef}
            aspect={window.innerWidth / window.innerHeight}
            fov={fov.current}
            position={[0, 0, 0]}
            onUpdate={self => self.updateProjectionMatrix()}
        />
    );
}

export default App;